import { createAjaxWithCredentials } from '../../../_shared/utils/ajax.ts';
import { createRestProvider } from '../../../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../../../_shared/utils/urlFactory.ts';
import { RepositoryWithContext } from '../../../repositories/interfaces/repository.type.ts';
import { AiSearchRepository } from './AiSearchRepository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const aiSearchRepository: RepositoryWithContext<AiSearchRepository> = {
  getListingItems(requestContext, variantId, filter, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/ai-search/query/${variantId}`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },
};
