import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IUserCustomAppRepository } from './interfaces/IUserCustomAppRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ICustomAppResponseServerModel } from './serverModels/ICustomAppRepository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export const userCustomAppRepository: RepositoryWithContext<IUserCustomAppRepository> = {
  getAll(
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<ICustomAppResponseServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/user-custom-apps`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },
};
